<template>
  <div class="AppMainAbout">
    <!--    <parallax>-->
    <!--      <img src="./../assets/images/about__bg.png" alt="bg-img">-->
    <!--    </parallax>-->
    <div class="AppMainAbout__inner container">
      <div class="AppMainAbout__block AppMainAbout__block--custom ">
        <app-title
          class="AppMainAbout__title--overwidth title--yellow title--medium"
          data-aos="fade-down"
          >{{ getSortedTexts[2]['text_' + this.selectedLang]}}</app-title
        >
        <div class="AppMainAbout__banner-wrapper">
          <div
            class="AppMainAbout__banner AppMainAbout__banner--custom AppMainAbout__banner--m-top"
            data-aos="fade-left"
          >
            <app-title
              class="AppMainAbout__title title title--small title--montserrat title--W500"
              >{{ getSortedTexts[3]['text_' + this.selectedLang]}}</app-title
            >
            <app-subtitle
              class="AppMainAbout__subtitle subtitle subtitle--medium"
              >{{ getSortedTexts[4]['text_' + this.selectedLang]}}
            </app-subtitle>
            <app-subtitle
              class="AppMainAbout__subtitle subtitle subtitle--medium"
            >
              {{ getSortedTexts[5]['text_' + this.selectedLang]}}
            </app-subtitle>
            <router-link
              to="/about"
              @click.native="scrollToTop"
              class="AppMainAbout__showmore-a"
              ><app-show-more class="AppMainAbout__showmore"
            /></router-link>
          </div>
        </div>
      </div>
      <div class="AppMainAbout__block">
        <app-title
          class="AppMainAbout__title--up-slider AppMainAbout__title title title--small title--montserrat title--W500"
          >{{ getSortedTexts[6]['text_' + this.selectedLang]}}</app-title
        >
        <app-slider
          class="AppMainAbout__slider"
          :sliders="sliders"
          data-aos="fade-right"
        />
        <div class="AppMainAbout__banner" data-aos="fade-down">
          <app-title
            class="AppMainAbout__title--down-slider AppMainAbout__title title title--small title--montserrat title--W500"
            >{{ getSortedTexts[6]['text_' + this.selectedLang]}}</app-title
          >
          <app-subtitle
            class="AppMainAbout__subtitle subtitle subtitle--medium AppMainAbout__banner--padding"
            >{{ getSortedTexts[7]['text_' + this.selectedLang]}}
          </app-subtitle>
          <div class="AppMainAbout__button">
            <router-link to="/menu">
              <app-button class="button--stroke">{{ menu['text_' + this.selectedLang]}}</app-button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle    from '../AppTitle.vue';
import AppSubtitle from '../AppSubtitle.vue';
import AppShowMore from '../AppShowMore.vue';
import AppSlider   from '../AppSlider.vue';
import AppButton   from '../AppButton.vue';
import {mapGetters} from "vuex";

export default {
  name: 'AppMainAbout',
  components: {
    AppTitle,
    AppSubtitle,
    AppShowMore,
    AppSlider,
    AppButton,
  },
  data() {
    return {
      menu: {
        text_ru: 'Меню',
        text_ua: 'Меню',
        text_pl: 'Menu',
        text_en: 'Menu',
      },
      text: {
        text_ru: 'О нас и о нашем деле:',
        text_ua: 'Про нас і про нашу справу:',
        text_pl: 'O nas i naszej działalności:',
        text_en: 'About us and our business:',
      },
      sliders: [
        { id: 1, img: 'about__slider__1' },
        { id: 2, img: 'buy-cake__slider__1' },
        { id: 3, img: 'about__slider__1' },
        { id: 4, img: 'buy-cake__slider__1' },
      ],
    };
  },

  computed: {
    ...mapGetters(['getSortedTexts']),
    ...mapGetters('lang', ['selectedLang'])
  },

  methods: {},
};
</script>

<style lang="scss">
.AppMainAbout {
  background: url('../../../../assets/images/about__bg.png') center no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $body-color;
    opacity: 0.93;
    z-index: 2;
  }
  &__inner {
    position: relative;
    padding-top: 215px;
    padding-bottom: 215px;
    z-index: 3;
    @include flex(center, center, column, nowrap);

    @include media-d-m($screen-desktop-large) {
      padding-top: 100px;
      padding-bottom: 200px;
    }

    @include media-d-m($screen-netbook) {
      padding-top: 53px;
      padding-bottom: 53px;
    }
  }
  &__block {
    @include flex(space-between, flex-start, row, nowrap);
    width: 100%;

    &--custom {
      @include flex(space-between, flex-start, column, nowrap);

      @include media-d-m($screen-netbook) {
        @include flex(flex-start, flex-start, column, nowrap);
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 275px;

      @include media-d-m($screen-desktop-large) {
        margin-bottom: 210px;
      }

      @include media-d-m($screen-netbook) {
        margin-bottom: 175px;
      }
    }

    @include media-d-m($screen-desktop-large) {
      @include flex(space-between, flex-start, row, nowrap);
    }

    @include media-d-m($screen-netbook) {
      @include flex(flex-start, flex-start, column, nowrap);
    }
  }

  &__slider {
    margin-left: -200px;
    @include media-d-m($screen-desktop-large) {
      margin-left: -400px;
    }

    @include media-d-m($screen-netbook) {
      margin-left: -30px;
      margin-bottom: 20px;
    }
  }

  &__banner {
    max-width: 500px;
    position: relative;
    right: 0;

    &--custom {
      position: relative;
      right: 0;
      margin-top: -50px;

      @include media-d-m($screen-netbook) {
        margin-top: 0px;
      }
    }

    @include media-d-m($screen-desktop-large) {
      max-width: 420px;
    }
    &--m-top {
      @include media-d-m($screen-desktop-large) {
        margin-top: 90px;
      }
    }
  }

  &__banner-wrapper {
    width: 100%;
    @include flex(flex-end, flex-start, row, nowrap);
  }

  &__showmore-a {
    @include media-d-m($screen-netbook) {
      width: 100%;
      text-align: right;
    }
  }

  &__showmore {
    align-self: flex-start;

    @include media-d-m($screen-netbook) {
      width: 100%;
      text-align: right;
    }
  }

  &__title {
    max-width: 314px;
    margin-bottom: 50px;
    &--up-slider {
      display: none;

      @include media-d-m($screen-netbook) {
        display: block;
      }
    }

    &--down-slider {
      @include media-d-m($screen-netbook) {
        display: none;
      }
    }
    @include media-d-m($screen-netbook) {
      margin-bottom: 47px;
    }
  }

  &__subtitle {
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }

    &:not(:first-of-type) {
      margin-bottom: 40px;
    }
  }

  &__banner--padding {
    margin-bottom: 100px;

    @include media-d-m($screen-desktop-large) {
      margin-bottom: 80px;
    }
    @include media-d-m($screen-netbook) {
      margin-bottom: 30px;
    }
  }
}
</style>
