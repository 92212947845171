<template>
  <div class="card">
    <div class="card__image">
      <app-show-more class="card__showmore" :href="href" />
      <img
        class="image"
        :class="customWidth"
        :src="require('@/assets/images/' + imageUrl + '.png')"
        alt=""
      />
    </div>
    <div
      class="card__banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/images/' + imageUrl + '.png') + ')',
      }"
    >
      <div class="card__banner__inner">
        <a :href="href"
          ><app-title
            class="card__title title title--small title--montserrat title--W500"
            >{{ title }}</app-title
          ></a
        >
        <app-subtitle
          class="card__subtitle subtitle subtitle--medium"
          @click="getIsmobyle()"
          >{{ subtitle }}</app-subtitle
        >
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle    from './AppTitle.vue';
import AppSubtitle from './AppSubtitle.vue';
import AppShowMore from './AppShowMore.vue';

export default {
  name: 'AppCard',
  components: {
    AppTitle,
    AppSubtitle,
    AppShowMore,
  },
  props: {
    imageUrl: String,
    title: String,
    subtitle: String,
    href: String || '#',
    customWidth: String,
  },

  data() {
    return {
      width: null,
    };
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
    },
  },
  created() {
    window.addEventListener('resize', this.updateWidth);
    this.updateWidth();
  },
};
</script>

<style lang="scss">
.card {
  &:hover .card__image:after {
    opacity: 0.65;
  }

  @include flex(center, center, column, nowrap);

  &--all-width {
    @include media-d-m($screen-netbook) {
      width: calc(100% + 60px);
    }
  }

  &:hover .card__showmore {
    opacity: 1;
  }

  &:hover .card__title {
    color: $yellow;
  }
  max-width: 300px;
  &__image {
    max-width: 300px;
    max-height: 200px;
    margin-bottom: 22px;
    position: relative;

    &::after {
      transition-property: opacity;
      transition-duration: 0.2s;
      transition-timing-function: ease;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $body-color;
      z-index: 2;
      opacity: 0;
    }

    @include media-d-m($screen-netbook) {
      display: none;
    }
  }

  &__showmore {
    @include flex(center, center, row, nowrap);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease;
  }

  &__banner {
    position: relative;
    background-size: cover;
    background-position: center;

    &::after {
      transition-property: opacity;
      transition-duration: 0.2s;
      transition-timing-function: ease;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $body-color;
      z-index: 2;
      opacity: 1;
    }
    @include media-d-m($screen-netbook) {
      width: calc(100%);
      padding: 30px;

      &::after {
        transition-property: opacity;
        transition-duration: 0.2s;
        transition-timing-function: ease;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $body-color;
        z-index: 2;
        opacity: 1;
      }

      &:hover {
        &::after {
          opacity: 0.8;
        }
      }
    }

    &__inner {
      position: relative;
      z-index: 3;
    }
  }

  &__title {
    margin-bottom: 13px;
    transition: color 0.2s ease;
    cursor: pointer;
  }
}
.image {
  object-fit: cover;
}
</style>
