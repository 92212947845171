<template>
  <div class="lang">
    <div class="lang__box" :class="{ active: isActive }">
      <ul class="lang__items">
        <li
          class="lang__item"
          v-for="lang in this.getLangsList"
          :key="lang"
          @click="changeLangEvent(lang)"
        >
          {{ lang }}
        </li>
      </ul>
    </div>
    <div
      class="lang__now"
      @click="isActiveMethods"
      :class="{ active: isActive }"
    >
      {{ selectedLang }}
      <p class="lang__arrow" :class="{ active: isActive }">
        <icon-chevron-up />
      </p>
    </div>
  </div>
</template>
<script>
import IconChevronUp from '../../../components/svg/IconChevronUp.vue';
import {mapActions, mapGetters} from 'vuex';


export default {
  name: 'AppLang',
  components: {
    IconChevronUp,
  },
  data() {
    return {
      languages: ['ua', 'en', 'pl', 'ru'],
      current: 'ru',
      isActive: false,
    };
  },
  computed: {
    ...mapGetters('lang', ['getLangsList', 'selectedLang'])
  },
  methods: {
    ...mapActions('lang', ['changeLang']),

    changeLangEvent(lang) {
      this.changeLang(lang);
      this.isActive = !this.isActive;
    },

    isActiveMethods: function() {
      if (this.isActive) {
        return (this.isActive = false);
      } else {
        return (this.isActive = true);
      }
    },
  },
};
</script>

<style lang="scss">
.lang {
  @include text($h15, 400, $light-yellow, $f-montserrat);
  position: relative;

  &__now {
    @include flex(center, center, row, nowrap);
    cursor: pointer;
    transition: color 0.2s ease;

    &.active {
      color: $yellow;
    }
  }

  &__arrow {
    transition: transform, 0.2s ease;
    transform-origin: center, center;
    position: relative;
    left: 2px;
    top: -1px;
  }

  &__arrow.active {
    transform: rotate(-180deg);
    transform-origin: center, center;
    top: 5px;
  }

  &__item {
    margin-bottom: 7px;
    cursor: pointer;
    transition: color 0.2s ease;

    &:hover {
      color: $yellow;
    }
  }

  &__box {
    position: absolute;
    height: 0px;
    top: 0;
    overflow: hidden;
    transition-property: overflow, height, top;
    transition-duration: 0.2s;
    transition-timing-function: ease;

    &.active {
      height: 99px;
      top: -99px;
    }
  }
}
</style>
