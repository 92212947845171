<template>
  <div class="AppInteresting">
    <div class="AppInteresting__inner container" data-aos="fade-down">
      <app-title class="AppInteresting__title title--yellow title--medium "
        >{{ getSortedTexts[10]['text_' + selectedLang]}}</app-title
      >
      <div class="AppInteresting__cards">
        <app-card
          v-for="post in posts"
          :key="post.id"
          :imageUrl="post.imageUrl + post.id"
          :title="getSortedTexts[post.title]['text_' + selectedLang]"
          :subtitle="getSortedTexts[post.subtitle]['text_' + selectedLang]"
          :href="post.href"
          :customWidth="'AppInteresting__card'"
          class="AppInteresting__card card--all-width"
        />
      </div>
      <div class="AppInteresting__button">
        ,<router-link to="/blog"
          ><app-button class="button--stroke"
            >{{ showMore['text_' + selectedLang]}}</app-button
          ></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle  from '../AppTitle.vue';
import AppCard   from '../AppCard.vue';
import AppButton from '../AppButton.vue';
import {mapGetters} from "vuex";

export default {
  name: 'AppInteresting',
  components: {
    AppTitle,
    AppCard,
    AppButton,
  },

  data() {
    return {
      showMore: {
        text_ru: 'Показать больше',
        text_ua: 'Показати більше',
        text_pl: 'Pokaż więcej',
        text_en: 'Show more',
      },
      posts: [
        {
          id: 1,
          imageUrl: 'card__',
          title: 11,
          subtitle: 12,
          href: '#',
        },
        {
          id: 2,
          imageUrl: 'card__',
          title: 13,
          subtitle: 14,
          href: '#',
        },
        {
          id: 3,
          imageUrl: 'card__',
          title: 15,
          subtitle: 16,
          href: '#',
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['getSortedTexts']),
    ...mapGetters('lang', ['selectedLang'])
  },

  methods: {},
};
</script>

<style lang="scss">
.AppInteresting {
  &__inner {
    @include flex(center, center, column, nowrap);
    padding-top: 65px;
    padding-bottom: 158px;
  }

  &__title {
    padding-bottom: 99px;
    align-self: flex-start;
    @include media-d-m($screen-netbook) {
      padding-bottom: 56px;
    }
  }

  &__cards {
    @include flex(space-between, flex-start, row, nowrap);
    width: 100%;
    margin-bottom: 40px;

    @include media-d-m($screen-netbook) {
      @include flex(center, flex-start, column, nowrap);
      width: calc(100% + 60px);
      margin-bottom: 0;
    }
  }

  &__card {
    position: relative;
    width: 100%;

    &:not(:last-of-type) {
      margin-right: 20px;
    }
    @include media-d-m($screen-desktop-large) {
      max-width: 285px;
    }

    @include media-d-m($screen-netbook) {
      max-width: none;
      &:not(:last-of-type) {
        margin-right: 0;
      }
    }
  }

  &__button {
    width: 100%;
    @include flex(center, center, row, nowrap);

    @include media-d-m($screen-netbook) {
      @include flex(flex-start, center, row, nowrap);
    }
  }
}
</style>
