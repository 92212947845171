<template>
  <div class="AppSlider">
    <div class="AppSlider__inner">
      <div
        v-for="slide in sliders"
        :key="slide.id"
        v-show="slide.id === sliderActive"
        class="AppSlider__slide"
      >
        <div
          :style="{
            'background-image':
              'url(' +
              require('@/assets/images/' + slide.img + '.png') +
              ')',
          }"
          class="AppSlider__image"
        />
      </div>

      <div
        class="AppSlider__nav"
        :class="{ 'AppSlider__nav--reverse': navReverse }"
      >
        <button
          @click="prevSlider()"
          class="button button--slider button--stroke"
        >
          <icon-arrow class="iconArrow--body-color" />
        </button>
        <button
          @click="nextSlider()"
          class="button button--slider button--stroke"
        >
          <icon-arrow class="iconArrow--right iconArrow--body-color" />
        </button>
      </div>
      <app-button class="button--none" />
    </div>
  </div>
</template>

<script>
import AppButton from './AppButton';
import IconArrow from './svg/iconArrow.vue';
export default {
  name: '',
  components: {
    AppButton,
    IconArrow,
  },
  props: {
    sliders: Array,
    navReverse: Boolean,
  },
  data() {
    return {
      amountSlider: this.sliders.length,
      sliderActive: 1,
    };
  },

  methods: {
    nextSlider: function() {
      if (this.sliderActive !== this.amountSlider) {
        this.sliderActive++;
      } else {
        this.sliderActive = 1;
      }
    },

    prevSlider: function() {
      if (this.sliderActive !== 1) {
        this.sliderActive--;
      } else {
        this.sliderActive = this.amountSlider;
      }
    },
  },
};
</script>

<style lang="scss">
.AppSlider {
  max-width: 800px;
  width: calc(100% + 60px);
  height: 500px;

  @media screen and (max-width: 700px) {
    height: 400px;
  }

  @include media-d-m($screen-netbook) {
    width: calc(100% + 60px);
  }

  @include media-d-m($screen-tablet-small) {
    height: 300px;
  }

  @include media-d-m($screen-iphone-6) {
    height: 210px;
  }

  &__inner {
    height: 100%;
  }

  &--left .AppSlider__nav {
    @include flex(flex-start, center, row, nowrap);
    @include media-d-m($screen-netbook) {
      @include flex(flex-end, center, row, nowrap);
    }
  }

  &__slide {
    height: 100%;
  }

  &__image {
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &__nav {
    @include flex(flex-end, center, row, nowrap);
    position: relative;
    top: -45px;

    @include media-d-m($screen-netbook) {
      width: calc(100% - 60px);
      margin: 0 auto;
      top: -60px;
    }

    & button {
      margin-right: -1px;
    }

    &--reverse {
      @include flex(flex-start, center, row, nowrap);
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
</style>
