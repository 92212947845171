<template>
  <div id="AppMain">
    <AppOrderBlock />
    <AppMainAbout />
    <AppBuyCake />
    <AppInteresting />
  </div>
</template>

<script>
import AppOrderBlock  from '../components/TheMain/AppOrderBlock.vue';
import AppMainAbout   from '../components/TheMain/AppMainAbout.vue';
import AppBuyCake     from '../components/TheMain/AppBuyCake.vue';
import AppInteresting from '../components/TheMain/AppInteresting.vue';



export default {
  name: 'AppMain',
  components: {
    AppOrderBlock,
    AppMainAbout,
    AppBuyCake,
    AppInteresting,
  },
};
</script>

<style lang="scss"></style>
