<template>
  <div class="AppOrderBlock">
    <div class="AppOrderBlock__inner container">
      <app-title
          :class="{ active: this['getIsDropMenuActive'] }"
          class="AppOrderBlock__title"
      >{{ getSortedTexts[0]['text_' + this.selectedLang]}}
      </app-title>
      <app-subtitle
          :class="{ active: this['getIsDropMenuActive'] }"
          class="AppOrderBlock__subtitle"
      >
        {{ getSortedTexts[1]['text_' + this.selectedLang]}}
      </app-subtitle>
      <div
          :class="{ active: this['getIsDropMenuActive'] }"
          class="AppOrderBlock__section-button"
      >
        <div class="AppOrderBlock__button">
          <router-link to="/delivery">
            <app-button class="AppOrderBlock__button button--fill"
            >{{ buttonText1['text_' + this.selectedLang]}}
            </app-button
            >
          </router-link>
        </div>
        <div class="AppOrderBlock__button">
          <router-link to="/pies">
            <app-button class="AppOrderBlock__button button--stroke"
            >{{ buttonText2['text_' + this.selectedLang]}}
            </app-button
            >
          </router-link
          >
        </div>
      </div>
      <div class="AppOrderBlock__nav">
        <app-lang/>
        <app-social/>
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle from '../AppTitle.vue';
import AppSubtitle from '../AppSubtitle.vue';
import AppButton from '../AppButton.vue';
import AppSocial from '../AppSocial.vue';
import AppLang from '../../modules/lang/components/AppLang.vue';

import {mapGetters} from 'vuex';

export default {
  name: 'AppOrderBlock',
  components: {
    AppTitle,
    AppSubtitle,
    AppButton,
    AppSocial,
    AppLang,
  },

  data() {
    return {
      isActive: false,
      buttonText1: {
        text_ru: 'Заказать домой',
        text_ua: 'Замовити додому',
        text_pl: 'Zamów do domu',
        text_en: 'Order Home',
      },
      buttonText2: {
        text_ru: 'Заказать пирог',
        text_ua: 'Замовити пиріг',
        text_pl: 'Zamów ciasto',
        text_en: 'Order a pie',
      }
    };
  },

  computed: {
    ...mapGetters(['getIsDropMenuActive', 'getSortedTexts']),
    ...mapGetters('lang', ['selectedLang'])
  },
};
</script>

<style lang="scss">
.AppOrderBlock {
  @include flex(center, center, row, nowrap);
  background-image: url(../../../../assets/images/order-block__bg.png);
  background-position: 100% 0;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  margin-top: -115px;
  min-height: 100vh;

  @include media-d-m($screen-desktop-large) {
    background-position: 200% 0;
  }

  @include media-d-m($screen-netbook) {
    @include flex(center, flex-start, column, nowrap);
    background-position: 100% 0;
    margin-top: -105px;
  }

  @include media-d-m($screen-iphone-5) {
    margin-top: -34px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $body-color;
    opacity: 0;
    z-index: 1;
  }

  @include media-d-m($screen-desktop-large) {
    &::after {
      opacity: 0.75;
    }
  }

  &__inner {
    position: relative;
    z-index: 3;
    margin-top: 115px;

    @include media-d-m($screen-netbook) {
      margin-top: 0;
    }
  }

  &__title {
    max-width: 716px;
    margin-bottom: 35px;
    transition: opacity 0.2s ease;

    &.active {
      opacity: 0;
    }

    @include media-d-m($screen-desktop-large) {
      margin-bottom: 25px;
    }

    @include media-d-m($screen-hd) {
      font-size: $h56;
    }
    @include media-d-m($screen-netbook) {
      font-size: $h31;
      margin-bottom: 20px;
    }
  }

  &__subtitle {
    max-width: 380px;
    margin-bottom: 65px;
    transition: opacity 0.2s ease;

    @include media-d-m($screen-desktop-large) {
      margin-bottom: 100px;
    }

    @include media-d-m($screen-netbook) {
      margin-bottom: 110px;
    }

    &.active {
      opacity: 0;
    }
  }

  &__section-button {
    @include flex(flex-start, center, row, nowrap);
    margin-bottom: 135px;
    transition: opacity 0.2s ease;

    &.active {
      opacity: 0;
    }

    @include media-d-m($screen-hd) {
      margin-bottom: 67px;
    }

    @include media-d-m($screen-desktop-large) {
      margin-bottom: 194px;
    }

    @include media-d-m($screen-netbook) {
      @include flex(flex-start, flex-start, column, nowrap);
      margin-bottom: 0;
    }
  }

  &__button {
    @include media-d-m($screen-netbook) {
      width: 100%;

      a {
        width: 100%;
      }
    }
  }

  &__button:not(:last-of-type) {
    margin-right: 32px;
    @include media-d-m($screen-netbook) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  &__nav {
    width: 100%;
    @include flex(space-between, center, row, nowrap);
    @include media-d-m($screen-netbook) {
      display: none;
    }
  }
}
</style>
