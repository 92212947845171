<template>
  <div class="AppBuyCake">
    <div class="AppBuyCake__inner container">
      <div class="AppBuyCake__banner" data-aos="fade-down">
        <app-title
          class="AppBuyCake__title AppBuyCake__title--down-slider title title--small title--montserrat title--W500"
          >{{ getSortedTexts[8]['text_' + this.selectedLang]}}</app-title
        >
        <app-subtitle class="AppBuyCake__subtitle subtitle subtitle--medium"
          >{{ getSortedTexts[9]['text_' + this.selectedLang]}}
        </app-subtitle>
        <router-link to="/pies" @click.native="this['scrollToTop']">
          <app-button class="button--stroke"
            >{{ buttonText2['text_' + this.selectedLang]}}</app-button
          ></router-link
        >
      </div>

      <app-slider
        class="AppBuyCake__slider slider--left"
        :right="true"
        :sliders="sliders"
        :navReverse="true"
        data-aos="fade-left"
      />

      <app-title
        class=" AppBuyCake__title AppBuyCake__title--up-slider title title--small title--montserrat title--W500"
        >{{ getSortedTexts[8]['text_' + this.selectedLang]}}</app-title
      >
    </div>
  </div>
</template>

<script>
import AppTitle       from '../AppTitle.vue';
import AppSubtitle    from '../AppSubtitle.vue';
import AppSlider      from '../AppSlider.vue';
import AppButton      from '../AppButton.vue';
import {mapActions, mapGetters} from 'vuex';
export default {
  name: 'AppBuyCake',
  components: {
    AppTitle,
    AppSubtitle,
    AppSlider,
    AppButton,
  },
  data() {
    return {
      buttonText2: {
        text_ru: 'Заказать пирог',
        text_ua: 'Замовити пиріг',
        text_pl: 'Zamów ciasto',
        text_en: 'Order a pie',
      },
      sliders: [
        { id: 1, img: 'buy-cake__slider__1' },
        { id: 2, img: 'about__slider__1' },
      ],
    };
  },
  computed: {
    ...mapGetters(['getSortedTexts']),
    ...mapGetters('lang', ['selectedLang'])
  },
};
</script>

<style lang="scss">
.AppBuyCake {
  overflow: hidden;

  &__inner {
    @include flex(space-between, flex-start, row, nowrap);
    padding-top: 192px;
    padding-bottom: 192px;
    @include media-d-m($screen-netbook) {
      padding-top: 53px;
      padding-bottom: 53px;
    }

    @include media-d-m($screen-netbook) {
      @include flex(flex-start, flex-start, column-reverse, nowrap);
    }
  }

  &__slider {
    margin-right: -200px;
    @include media-d-m($screen-desktop-large) {
      margin-right: -400px;
    }

    @include media-d-m($screen-netbook) {
      margin-right: 0;
      margin-left: -30px;
      margin-bottom: 20px;
    }
  }

  &__banner {
    @include flex(center, flex-start, column, wrap);
  }

  &__title {
    max-width: 405px;
    margin-bottom: 52px;

    &--up-slider {
      display: none;
      @include media-d-m($screen-netbook) {
        display: block;
        margin-bottom: 30px;
      }
    }

    &--down-slider {
      @include media-d-m($screen-netbook) {
        display: none;
      }
    }
  }

  &__subtitle {
    max-width: 500px;
    margin-bottom: 100px;

    @include flex(flex-start, center, row, nowrap);

    @include media-d-m($screen-desktop-large) {
      max-width: 430px;
    }

    @include media-d-m($screen-netbook) {
      margin-bottom: 30px;
    }
  }
}
</style>
